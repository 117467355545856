import { text, html } from '../util';
import { setComplete } from '../completion';
import { getWebring } from '../webring';

const neofetchProperties = [
  {name: 'timezone', value: 'UTC+3'},
  {name: 'fav language', value: 'lua'},
  {name: 'you lost', value: 'the game'},
  {name: 'btd6 hero main', value: 'sauda'},
  {name: 'bot', value: 'true'},
  {name: 'gender feet value', value: '0.9664'},
  {name: 'preferred editor', value: 'vscode'},
  {name: 'shell', value: 'qsh'},
  {name: 'preferred terminal', value: 'alacritty'},
  {name: 'distance away from your current location', value: `${Math.floor((Math.random() + 0.2) * 10) / 10}km`},
  {name: 'did you know that qsh stands for', value: 'quaker shell'},
  {name: 'degrees of seperation from matpat', value: '3'},
  {name: 'eternally', value: 'sleepy'},
  {name: 'trans', value: 'rights'},
  {name: 'resolution', value: `${window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth}x${window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight}`},
  {name: 'michael', value: '<img src="michael.png" width="16px" height="16px">'},
  {name: 'run ls -a', value: 'to see hidden files!'},
  {name: 'sus', value: 'false'},
  {name: 'useragent', value: navigator.userAgent},
  {name: 'ladies! get the best vibrator at', value: '<a target="_blank" href="https://noti.tg/">noti.tg</a>'},
  {name: 'brain cell count', value: '1'},
  {name: 'please play ror2 with me', value: '<a target="_blank" href="https://steamcommunity.com/id/oatmealine/">https://steamcommunity.com/id/oatmealine/</a>'},
  {name: 'music collection size', value: '107.6GB, 4609 songs, 10 days, 18 hours and 42 minutes'},
  {name: 'logmabar', value: '㏒㎃㍴'},
  {name: 'love2d', value: 'stan'},
  {name: 'xss', value: '<a href="javascript:alert(\'xss doesnt even make sense in this context. i made the website. theres no html thats fetched externally\')">click me</a>'},
  {name: 'idk', value: 'i\'m out of ideas'}
];

export default (args) => {
  if (args.includes('--help')) {
    return text(`Usage: neofetch [OPTION]...
Neofetch is a CLI system information tool written in BASH. Neofetch
displays information about your system next to an image, your OS logo,
or any ASCII file of your choice.

Options:
-v                         verbose (add more -vs for more verbosity, eg. -vv)
-s                         silent`);
  } else {
    let verbosity = args.filter(i => i.startsWith('-v')).map(i => i.length - 1).reduce((a, b) => a + b, 0)
        - args.filter(i => i.startsWith('-s')).map(i => i.length - 1).reduce((a, b) => a + b, 0);

    if (verbosity < -4) {
      setComplete(6);
      return html(`<b class="black">temma${' really'.repeat(-4 - verbosity - 1)} cute</b>`);
    }

    let extraProperties = [];
    if (verbosity > 0) {
      setComplete(5);
      if (verbosity > 2) setComplete(12);
      for (let i = 0; i < verbosity; i++) {
        extraProperties.push(neofetchProperties[i % neofetchProperties.length]);
      }
    }

    return html(`
<div class="neofetch h-card">
<img class="neofetch-img u-logo" src="favicon-310.png" width="310" height="310">
<div class="neofetch-text">
  <b class="magenta">oatmealine</b>@<b class="magenta">oat.zone</b><br>
  ----------------------------<br>
  <span class="p-note">
    hi i'm <b><a class="silentlink p-name u-url" href="https://oat.zone/">jill</a></b> (online known as <span class="p-nickname" title="pronounced oat-meal-een or oat-me-line, but i'm not picky">oatmealine</span>)<br>
    i do whatever funny stuff i decide i want to do!<br>
  </span>
  i'm a lot of things, too much to list here, but most notably:<br>
  <b>crystal</b>, <b>lua</b> and <b>haskell</b> dev, <b>nixos</b> user, <a rel="me noreferrer" target="_blank" href="https://www.shadertoy.com/user/oatmealine">shader</a> programmer,
  <a rel="me noreferrer" target="_blank" href="https://modfiles.oat.zone/">modfile</a> creator, <a rel="me noreferrer" target="_blank" href="https://oatmealine.itch.io/">gamedev</a>,
  <a rel="noreferrer" target="_blank" href="https://www.youtube.com/playlist?list=PLfCwHeiLZk7gFiKxIVwdskcCixM_DQCYH">gd creator</a>, <b>webdev</b>, sometimes <a rel="noreferrer" target="_blank" href="https://cohost.org/oatmealine/tagged/my%20art">artist</a> and rarely <a rel="me noreferrer" target="_blank" href="https://soundcloud.com/oatmealine/">composer</a><br>
  ${verbosity >= -3 ? '<br><b>pronouns:</b> it/its, they/them, she/her<br>' : ''}
  ${verbosity >= -2 ? `
  <b>links:</b>
  <a href="https://blog.oat.zone/" rel="me noreferrer" target="_blank">blog</a>
  <a href="https://git.oat.zone/oat" rel="me noreferrer" target="_blank">git</a>
  <a href="https://cohost.org/oatmealine" rel="me noreferrer" target="_blank">cohost</a>
  <a href="https://www.youtube.com/channel/UCrRQO0JcAmrv0hAF5Do2Q5w" rel="me noreferrer" target="_blank">youtube</a>
  <br>
  ` : ''}
  ${verbosity >= -1 ? `
  <b>contact:</b>
  <a href="mailto:me@oat.zone" rel="me" class="u-email">email</a>
  <a id="discord"
    onclick="navigator.clipboard.writeText('oatmealine#5397'); this.classList.remove('copyable'); this.classList.add('copied');"
    onmouseover="this.classList.add('copyable'); this.classList.remove('copied')"
  ><span class="underline">discord</span></a>
  <a href="https://t.me/oatmealine" rel="me noreferrer" target="_blank">telegram</a>
  <a href="xmpp:oat@a3.pm" rel="me">xmpp</a>
  <a href="https://matrix.to/#/@gayrobot:egirls.gay" rel="me noreferrer" target="_blank">matrix</a>
  <br>
  ` : ''}
  ${extraProperties.map(p => `<b>${p.name}:</b> ${p.value}<br>`).join('')}
  ${verbosity >= 0 ? `
  <span id="webring">${getWebring()}</span><br>
  <b class="black">part of <a target="_blank" rel="noreferrer" href="https://dark-firepit.cloud">dark-firepit</a></b><br>
  ` : ''}
  <br>
  <pre><span class="black-bg">   </span><span class="red-bg">   </span><span class="green-bg">   </span><span class="yellow-bg">   </span><span class="blue-bg">   </span><span class="magenta-bg">   </span><span class="cyan-bg">   </span><span class="white-bg">   </span></pre>
  <pre style="margin-top: -0.2em"><span class="bright-black-bg">   </span><span class="bright-red-bg">   </span><span class="bright-green-bg">   </span><span class="bright-yellow-bg">   </span><span class="bright-blue-bg">   </span><span class="bright-magenta-bg">   </span><span class="bright-cyan-bg">   </span><span class="bright-white-bg">   </span></pre>
</div>
</div>
      `);
  }
};